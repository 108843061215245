<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}驻派计划</span>
		</div>
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="计划名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :clearable="true" :disabled="isShowDetail" placeholder=" 请输入" maxlength="20"></el-input>
			</el-form-item>
			<el-form-item label="驻派组" prop="medicalWorkerGroupId">
				<el-select v-model="ruleForm.medicalWorkerGroupId" :disabled="isShowDetail" @change="changeWorker" placeholder="请选择">
					<el-option v-for="item in workerGroupList" :disabled="!item.enabled" :key="item.id" :label="item.name" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="驻派时间" prop="startTime" class="w50 mr0">
				<el-date-picker :disabled="isShowDetail" v-model="ruleForm.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="开始时间">
				</el-date-picker>
			</el-form-item>
			<span style="margin: 0 12px">-</span>
			<el-form-item label="" prop="endTime" class="w50 mr0">
				<el-date-picker
					@change="endTimeChange"
					:disabled="isShowDetail"
					v-model="ruleForm.endTime"
					value-format="yyyy-MM-dd"
					type="date"
					placeholder="结束时间"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="驻派范围" required>
				<div class="columns columns7" v-for="(item, index) in ruleForm.resourceAllocations" :key="index">
					<!-- <el-select v-model="areaInfo" :class="[item.empty ? 'showBorder' : '']" placeholder="区县" @change="changeUser" :disabled="isShowDetail">
							<el-option v-for="item in option" :key="item.id" :label="item.title" :value="item.code"></el-option>
						</el-select> -->

					<el-input v-model="cityInfo.title" disabled placeholder=""></el-input>
					<el-input v-model="countyInfo.title" disabled placeholder=""></el-input>
					<!-- <el-input v-model="areaInfo.title" disabled placeholder=""></el-input> -->
					<el-form-item
						:prop="'resourceAllocations.' + index + '.stressCode'"
						:rules="{
							required: true,
							message: '请选择',
							trigger: 'change',
						}"
					>
						<el-select v-model="item.stressCode" placeholder="街道" @change="changeUser($event, index, 'stressCode')" :disabled="isShowDetail">
							<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						:prop="'resourceAllocations.' + index + '.villageCode'"
						:rules="{
							required: true,
							message: '请选择',
							trigger: 'change',
						}"
					>
						<el-select v-model="item.villageCode" placeholder="村" @change="changeUser($event, index, 'villageCode')" :disabled="isShowDetail">
							<el-option v-for="item in item.areaList" :key="item.id" :label="item.title" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<span @click="toSetRule(item, index)" :class="[item.noRule ? 'noRule' : '']">驻派规则设定</span>
					<i class="iconfont icon-tianjia3" @click="onNew(index)"></i>
					<i class="iconfont icon-a-shanchu2" @click="onDelete(index)"></i>
				</div>
			</el-form-item>
			<el-form-item label="驻派工作内容" prop="moduleChecked">
				<el-select v-model="ruleForm.moduleChecked" :disabled="isShowDetail" multiple @change="changeSelect($event, 'M')" placeholder="请选择">
					<el-option v-for="item in moduleList" :disabled="!item.enabled" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item label="配置资源" required>
				<div class="columns columns4" v-for="(item, index) in ruleForm.resourceAllocations" :key="index">
					<el-form-item
						:key="item.id"
						:prop="'userList.' + index + '.name'"
						:rules="{
							required: true,
							message: '请选择',
							trigger: 'change',
						}"
					>
						<el-select v-model="item.name" :class="[item.empty ? 'showBorder' : '']" placeholder="请选择" @change="changeUser" :disabled="isShowDetail">
							<el-option v-for="item in option" :key="item.id" :label="item.title" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<span>配置</span>
					<i class="iconfont icon-tianjia3" @click="onNew(index)"></i>
					<i class="iconfont icon-shanchu" @click="onDelete(index)"></i>
				</div>
			</el-form-item> -->
			<el-form-item label="车辆选择" prop="carChecked">
				<el-select v-model="ruleForm.carChecked" :disabled="isShowDetail" multiple @change="changeSelect($event, 'V')" placeholder="请选择">
					<el-option v-for="item in carList" :disabled="!item.enabled" :key="item.id" :label="item.licenseNumber" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备选择" prop="toolChecked">
				<el-select v-model="ruleForm.toolChecked" :disabled="isShowDetail" multiple @change="changeSelect($event, 'D')" placeholder="请选择">
					<el-option v-for="item in toolList" :disabled="!item.enabled" :key="item.id" :label="item.name" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="func-btn">
				<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
				<el-button v-else type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		RsForm: () => import('@/components/RsForm/index.vue'),
	},
	data() {
		return {
			ruleForm: { resourceAllocations: [{ stressCode: '', villageCode: '' }], tourPlanConfigurationItemMap: {} },
			createdVisible: false,
			workerGroupList: [], //驻派组成员
			moduleList: [],
			carList: [],
			toolList: [],
			villageList: [],
			areaList: [],
			areaInfo: {},
			cityInfo: {},
			countyInfo: {},
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				medicalWorkerGroupId: [{ required: true, message: '请选择', trigger: 'blur' }],
				startTime: [{ required: true, message: '请选择', trigger: 'blur' }],
				endTime: [{ required: true, message: '请选择', trigger: 'blur' }],
				moduleChecked: [{ required: true, message: '请选择', trigger: 'blur' }],
				carChecked: [{ required: true, message: '请选择', trigger: 'blur' }],
				toolChecked: [{ required: true, message: '请选择', trigger: 'blur' }],
			},
			saveLoading: false,
			backInfo: {},
			link: '',
		};
	},
	props: {
		isShowDetail: {
			type: Boolean,
			default: false,
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.backInfo = JSON.parse(sessionStorage.getItem('rulesItem')) || '';
		if (this.backInfo) {
			this.init();
		}
	},
	methods: {
		endTimeChange(e) {
			this.ruleForm.endTime = e + ' 23:59:59';
		},
		// 选择
		changeSelect(e, type) {
			console.log(e, type);
			let arr = e.map((item) => {
				return {
					itemTypeCode: type,
					itemId: item,
				};
			});
			this.ruleForm.tourPlanConfigurationItemMap[type] = arr;
			// this.ruleForm.tourPlanConfigurationItemMap
			// for (const key in this.ruleForm.tourPlanConfigurationItemMap) {
			// 	if (Object.hasOwnProperty.call(object, key)) {
			// 		const element = object[key];

			// 	}
			// }
		},
		// 选择驻派组
		changeWorker(e) {
			let currentGroup = this.workerGroupList.find((item) => item.id == e).medicalWorkers;
			console.log(currentGroup);
			sessionStorage.setItem('currentGroup', JSON.stringify(currentGroup));
		},
		// 获取村列表
		getVillageList(code) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取村列表
		getAreaList(code, index) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						// this.areaList = res.data.collection || [];
						// this.ruleForm.resourceAllocations[index].areaList = res.data.collection || [];
						this.$set(this.ruleForm.resourceAllocations[index], 'areaList', res.data.collection || []);
					}
				})
				.catch((e) => {});
		},
		// 获取驻派组列表
		// getUserList() {
		// 	this.$http
		// 		.get(this.api['MedicalWorkerGroups#index'].href, { params: { current: 1, size: 999, discriminationCode: 'D' } })
		// 		.then((res) => {
		// 			if (res.data && res.data.success) {
		// 				this.workerGroupList = res.data.collection || [];
		// 			}
		// 		})
		// 		.catch((e) => {});
		// },
		// 获取模块内容
		getModuleList() {
			this.$http
				.get(this.api['Dictionaries#index'].href, {
					params: { subjectTypeCode: 'RegionManagementGroup', subjectId: '~', code: 'DISPATCH_PLAN_MODULE' },
				})
				.then((resp) => {
					if (resp.data && resp.data.success) {
						this.$http
							.get(this.api['DictionaryItems#index'].href, { params: { current: 1, size: 999, dictionaryId: resp.data.collection[0].id } })
							.then((res) => {
								if (res.data && res.data.success) {
									this.moduleList = res.data.collection || [];
								}
							})
							.catch((e) => {});
					}
				})
				.catch((e) => {});
		},
		// 获取车辆内容
		getCarList() {
			this.$http
				.get(this.api['Vehicles#index'].href, { params: { current: 1, size: 999 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.carList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取设备内容
		getToolList() {
			this.$http
				.get(this.api['Devices#index'].href, { params: { current: 1, size: 999 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.toolList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 	前往设置规则
		toSetRule(item, index) {
			if (!this.ruleForm.medicalWorkerGroupId) {
				this.$message.warning('请先选择驻派组');
				return;
			}
			item.isShowDetail = this.isShowDetail;
			sessionStorage.setItem('ruleIndex', index);
			sessionStorage.setItem('ruleForm', JSON.stringify(this.ruleForm));
			sessionStorage.setItem('link', JSON.stringify(this.link));
			this.$router.push({ name: 'residencyRuleSet', query: item });
		},
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					console.log(this.ruleForm);
					let num = 0;
					this.ruleForm.resourceAllocations.forEach((item) => {
						if (!item.medicalWorkerIds || item.medicalWorkerIds.length == 0) {
							num++;
							this.$set(item, 'noRule', true);
						}
					});
					if (num > 0) {
						this.$message.warning('请设置规则');
						return;
					}
					this.saveLoading = true;
					this.ruleForm.discriminationCode = 'D';
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, this.ruleForm) : this.$http.post(this.link, this.ruleForm);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								// this.uploadImg(res.data.collection[0].id);
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
							}
						})
						.catch(() => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		// 新增成员
		onNew(index) {
			this.ruleForm.resourceAllocations.push({ stressCode: '', villageCode: '', tourPlanConfigurationItemMap: {} });
		},
		onDelete(index) {
			if (this.ruleForm.resourceAllocations.length == 1) return;
			this.ruleForm.resourceAllocations.splice(index, 1);
		},
		changeUser(e, index, file) {
			console.log(e, index);
			if (file == 'stressCode') {
				this.getAreaList(e, index);
			}
			// this.ruleForm.resourceAllocations[index]
			this.$set(this.ruleForm.resourceAllocations[index], file, e);
		},
		async init(params, link) {
			let res = await this.$http.get(this.api['MedicalWorkerGroups#index'].href, { params: { current: 1, size: 999, discriminationCode: 'D' } });
			if (res.data && res.data.success) {
				this.workerGroupList = res.data.collection || [];
			}
			this.getModuleList();
			this.getCarList();
			this.getToolList();
			let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
			if (userInfo && userInfo.chinaRegionDtoList && userInfo.chinaRegionDtoList.length > 0) {
				this.cityInfo = userInfo.chinaRegionDtoList[1];
				this.countyInfo = userInfo.chinaRegionDtoList[2];
				this.getVillageList(this.countyInfo.code);
			}
			// 设置规则返回回显
			if (this.backInfo && params != 'new' && !params) {
				this.$emit('update:isShowDetail', Boolean(this.$route.query.isShowDetail));
				this.ruleForm = JSON.parse(sessionStorage.getItem('ruleForm'));
				this.link = JSON.parse(sessionStorage.getItem('link'));
				let index = sessionStorage.getItem('ruleIndex');
				// this.getAreaList(this.backInfo.stressCode,index);
				this.ruleForm.resourceAllocations[index] = this.backInfo;
				sessionStorage.removeItem('rulesItem');
				sessionStorage.removeItem('ruleForm');
			}
			// 修改查看回显
			if (params && params != 'new') {
				this.$http.get(params._links.load.href).then((res) => {
					this.link = params._links.update.href;
					this.$nextTick(() => {
						this.createdVisible = true;
					});
					let info = res.data.collection[0];
					this.ruleForm = info;
					this.ruleForm.medicalWorkerGroupId = info.medicalWorkerGroup?.id;
					let currentGroup = this.workerGroupList.find((item) => item.id == info.medicalWorkerGroup.id).medicalWorkers;
					sessionStorage.setItem('currentGroup', JSON.stringify(currentGroup));
					this.ruleForm.moduleChecked = [];
					this.ruleForm.toolChecked = [];
					this.ruleForm.carChecked = [];
					this.ruleForm.resourceAllocations.forEach((item, index) => {
						this.$set(item, 'stressCode', item.regions[3].code);
						this.getAreaList(item.regions[3].code, index);
						this.$set(item, 'villageCode', item.regions[4].code);
					});

					for (const key in info.tourPlanConfigurationItemMap) {
						if (key == 'D') {
							info.tourPlanConfigurationItemMap[key].forEach((item) => {
								this.ruleForm.toolChecked.push(item.item.id);
								this.$set(item, 'itemId', item.item.id);
							});
						} else if (key == 'M') {
							info.tourPlanConfigurationItemMap[key].forEach((item) => {
								this.ruleForm.moduleChecked.push(item.item.id);
								this.$set(item, 'itemId', item.item.id);
							});
						} else {
							info.tourPlanConfigurationItemMap[key].forEach((item) => {
								this.ruleForm.carChecked.push(item.item.id);
								this.$set(item, 'itemId', item.item.id);
							});
						}
					}

					info.resourceAllocations.map((item) => {
						let medicalWorkerIds = [];
						item.medicalWorkers.map((item1) => {
							medicalWorkerIds.push(item1.id);
						});
						this.$set(item, 'medicalWorkerIds', medicalWorkerIds);
					});
					this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
				});

				return;
			}

			// 新增
			this.createdVisible = true;
			if (params == 'new') {
				this.ruleForm = this.$options.data().ruleForm; //清空表单
				this.link = link;
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
			}
		},
		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
.showBorder {
	border: 1px solid red;
}

.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.columns {
	margin-bottom: 12px;
	display: grid;
	gap: 12px;
	i {
		line-height: 32px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
	}
	i:hover {
		color: #1db9b1;
	}
	.icon-shanchu:hover {
		color: #f56c6c;
	}
	span {
		color: #1db9b1;
		cursor: pointer;
	}
}
.columns:nth-last-child(1) {
	margin-bottom: 0;
}
.columns7 {
	grid-template-columns: 77px 77px 77px 77px 84px 16px 16px;
}
.columns3 {
	grid-template-columns: 440px 16px 16px;
}
.columns4 {
	grid-template-columns: 400px 28px 16px 16px;
}

.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
.flex-tow {
	display: flex;
	align-items: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 16px);
	display: inline-block;
}
.noRule {
	text-decoration: underline;
	color: red !important;
}
</style>